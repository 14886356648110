import React, { useEffect } from "react";
import SEO from "../components/SEO";
import SchoolsHeroSection from "@src/components/partials/schools/SchoolsHeroSection";
import PerksSection from "@src/components/partials/schools/PerksSection";
import TraitsSection from "@src/components/TraitsSection";
import AlternatedFeaturesSection from "@src/components/AlternatedFeaturesSection";
import FeedbacksSection from "@src/components/FeedbacksSection";
import FAQSection from "@src/components/partials/schools/FAQSection";
import CTASection from "@src/components/CTASection";
import { logEvent } from "@src/utils/logClient";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const Schools = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        common_data_schools {
          traits
          features
          feedbacks
          faq_items
          classdojo_help_link_url
          cta_background_image_url
        }
        page_preschool {
          features
          hero_button_url
        }
        page_schools {
          hero_video_image {
            id
            filename_disk
          }
          hero_video_url
          perks
          perks_check_icon {
            id
            filename_download
            filename_disk
          }
          perks_check_alt
          star_badge_icon {
            id
            filename_download
            filename_disk
          }
          cta_button_url
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      common_data_schools: { feedbacks, traits, faq_items, classdojo_help_link_url, cta_background_image_url },
      page_preschool: { hero_button_url, features, cta_2_button_url },
      page_schools: { hero_video_image, hero_video_url, perks, perks_check_icon, star_badge_icon },
    },
  } = data;

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    logEvent({
      eventName: "teacher.classdojo_school.load_page",
      eventValue: location.href,
      metadata: params,
    });
  }, []);

  const SchoolsHeroProps = {
    hero_title: "directus.page_preschool.hero_title",
    hero_text: "directus.page_preschool.hero_text",
    hero_button_text: "directus.page_schools.hero_button_text",
    hero_button_advice: "directus.page_schools.hero_button_advice",
    hero_video_image,
    hero_video_alt: "directus.page_schools.hero_video_alt",
    hero_video_url,
    hero_button_url,
  };

  const AlternatedFeaturesSectionProps = {
    invert_desktop_order: true,
    title: "directus.page_preschool.features_title",
    text: "directus.page_preschool.features_text",
    content: features.map((feature, index) => ({
      ...feature,
      title: `directus.page_preschool.features.title_${index + 1}`,
      text: `directus.page_preschool.features.text_${index + 1}`,
    })),
  };

  const TraitsSectionProps = {
    traits_title: "directus.page_preschool.traits_title",
    content: traits,
    translationPath: "directus.common_data_schools.traits.",
    colored_bg: false,
    border_on_top: false,
    title_size: false,
  };

  const PerksSectionProps = {
    star_badge_icon,
    star_badge_alt: "directus.page_schools.star_badge_alt",
    perks_title: "directus.page_schools.perks_title",
    perks_text: "directus.page_schools.perks_text",
    perks,
    perks_check_icon,
    perks_check_alt: "directus.page_schools.perks_check_alt",
  };

  const FeedbacksSectionProps = {
    feedbacks_title: "directus.common_data_schools.feedbacks_title",
    feedbacks_numbers: "directus.common_data_schools.feedbacks_numbers",
    feedbacks_text: "directus.common_data_schools.feedbacks_text",
    feedbacks_text_source: "directus.common_data_schools.feedbacks_text_source",
    content: feedbacks,
    translationPath: "directus.common_data_schools.feedbacks.",
    colored_mode: true,
    back_slide_button_alt: "directus.common_data_schools.back_slide_button_alt",
    foward_slide_button_alt: "directus.common_data_schools.foward_slide_button_alt",
  };

  const FAQSectionProps = {
    faq_title: "directus.common_data_schools.faq_title",
    faq_items,
    faq_item_caret_alt: "directus.common_data_schools.faq_item_caret_alt",
    translationPath: "directus.common_data_schools.",
    faq_question: "directus.common_data_schools.faq_question",
    classdojo_help_link_text: "directus.common_data_schools.classdojo_help_link_text",
    classdojo_help_link_url,
  };

  const CTASectionProps = {
    cta_title: "directus.page_preschool.cta_title",
    cta_button_text: "directus.common_data_schools.cta_button_text",
    cta_button_url: hero_button_url,
    cta_background_image_url: cta_background_image_url,
  };

  return (
    <>
      <SEO
        title="Become a ClassDojo Preschool"
        description="ClassDojo gives your entire preschool one place to connect, communicate and share their learning for free"
        image="https://static.classdojo.com/img/schoolleader/schoolleader-share.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Become a ClassDojo Preschool",
          description:
            "ClassDojo gives your entire preschool one place to connect, communicate and share their learning for free",
          image: "https://static.classdojo.com/img/schoolleader/schoolleader-share.png",
        }}
      />
      <SchoolsHeroSection {...SchoolsHeroProps} />
      <TraitsSection {...TraitsSectionProps} />
      <PerksSection {...PerksSectionProps} />
      <AlternatedFeaturesSection {...AlternatedFeaturesSectionProps} />
      <FeedbacksSection {...FeedbacksSectionProps} />
      <FAQSection {...FAQSectionProps} />
      <CTASection {...CTASectionProps} />
    </>
  );
};
export default Schools;
